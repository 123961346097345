import React from "react";
import "../CSS/About.css";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";

import "swiper/css/navigation";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import Footer from "../Components/Footer";
import MetaTags from 'react-meta-tags';
// =========== Insta Img ======
import Service1 from "../Img/instaImg/baby-shower.png";
import Service2 from "../Img/instaImg/birthday.jpg";
import Service3 from "../Img/instaImg/kity-party.png";
import Service4 from "../Img/instaImg/marriage.jpg";
import Service5 from "../Img/instaImg/meeting.jpg";
import Service6 from "../Img/instaImg/recep.jpg";
import Service7 from "../Img/instaImg/ring.jpg";
import aboutbann from "../Img/gallery/gallery14.webp";
const About = () => {
  return (
    <>
      {/* ========== About Banner ========= */}
      <MetaTags>
      <title>Best Banquet in Kandivali | Classic Party Hall</title>
        <meta title="Best Banquet in Kandivali | Classic Party Hall" />
        <meta
          name="description"
          content="Experience unmatched sophistication and service at the Best Banquet in Kandivali. Elevate your events with our exquisite venue, tailored to perfection."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://classicvillapartyhall.com/best-banquet-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Banquet in Kandivali | Classic Party Hall" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://classicvillapartyhall.com/"/>
        <meta property="og:description" content="Experience unmatched sophistication and service at the Best Banquet in Kandivali. Elevate your events with our exquisite venue, tailored to perfection." />
        <meta property="og:image" content="https://classicvillapartyhall.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <div className="AboutBanner">
        <div className="container">
          <h1 className="AboutBanner-title">
          Best Banquet in Kandivali
          </h1>
        </div>
      </div>

      {/* ========== About section ============= */}
      <section className="about-area py-5 mt-5 mb-5">
        
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInLeft",
                }}
              >
                <div className="about-img">
                  <img src={aboutbann} alt="img" />
                </div>
                <div className="about-experience">
                  <h2>
                    10 <span>+</span>
                  </h2>
                  <span className="about-experience-text">
                    Years Of Experience
                  </span>
                </div>
                <div className="about-img-shape">
                  <img
                    src="https://live.themewild.com/lovex/assets/img/shape/01.svg"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-right wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">
                    Elevate Your Celebrations <span>Moment with</span> Classic Villa Party Hall!
                  </h2>
                </div>
                <p className="about-text">
                Introducing Classic villa best banquet in kandivali, a premier venue with ac hall perfect for 75 to 250 people for discerning individuals seeking unparalleled elegance and seamless event execution. With a legacy of excellence, our banquet hall stands as a symbol of sophistication and class. From meticulously designed interiors to top-notch amenities, we offer a refined setting for weddings, corporate events, and social gatherings. Our professional team is dedicated to exceeding expectations, ensuring every event is executed with precision and flair. Discover a venue where professionalism meets perfection – best banquet in kandivali. 
                </p>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>Premier venue, AC hall for 75-250 people.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>
                        Meticulously designed interiors, top-notch amenities.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>
                        Professional team ensures precision, flair in execution.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="about-bottom">
                  <Link to='/best-banquet-near-kandivali'>
                  <a href="/best-banquet-near-kandivali" className="theme-btn">
                    Read More <i class="fa-solid fa-arrow-right"></i>
                  </a>
                  </Link>
                  <div className="about-call">
                    <div className="about-call-icon">
                      <i className="fa fa-phone" />
                      {/* <i className="fa fa-user-headset" /> */}
                    </div>
                    <div className="about-call-content">
                      <span>Call Us Anytime</span>
                      <h5 className="about-call-number">
                        <a href="tel:+91 9987220795">+91 99872 20795
</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========= About Counter ========= */}
      <section className="counter-area mt-0 pb-5">
        <div className="container">
          <div className="counter-wrapper">
            <div className="row row-cols-lg-4 row-cols-2">
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-suitcase" /> */}
                    <i class="fa-regular fa-calendar-days"></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={10} duration={5} />
                    </span>
                    <h6 className="title">+ Years Of Exprience </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-wedding-arch" /> */}
                    <i class="fa fa-user"></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={1200} duration={5} />
                    </span>
                    <h6 className="title">+ Number Of Client</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-pastor" /> */}
                    <i class="fa-regular fa-face-smile"></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={1000} duration={5} />
                    </span>
                    <h6 className="title">+ Happy Couples</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    {/* <i className="flaticon-placeholder" /> */}
                    <i class="fa fa-star"></i>
                  </div>
                  <div>
                    <span className="counter">
                      <CountUp end={500} duration={5} />
                    </span>
                    <h6 className="title">+ Person Review</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== About Cta ========== */}
      <section className="cta-area pb-5">
        <div className="container">
          <div className="cta-wrapper">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <div className="cta-text">
                  <h2>We Are Ready To Celebrate Your All Types Of Event</h2>
                  <p>
                  With meticulous attention to detail and a commitment to excellence, we create tailored experiences that reflect the uniqueness of each event.
                  </p>
                </div>
                <a
                  href="tel:+9199872 20795"
                  className="theme-btn mt-30"
                  target="blank"
                >
                  Contact Now <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== About Testimonal ========= */}
      <section className="testimonial-area bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Testimonials</span>
                <h2 className="site-title">
                  What Our Client <span>Say's</span>
                </h2>
                {/* <div className="header-img-divider">
                  <img
                    src="https://live.themewild.com/lovex/assets/img/icon/divider.png"
                    alt="Engagement hall in Goregaon West"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="homeTestimoial"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Ajanta Party Hall Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/01.jpg"
                        alt="Borivali West kitty party venue"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>vipul pai</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Pleasant stay. Spacious and clean rooms. Mr. Nitin  really helped us customise our stay duration according to our needs and was cordial. highly recommend this property for your next stay.
                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Birthday party hall in Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/02.jpg"
                        alt="Conference party venue Borivali West"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Gauresh Mahadik</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Excellent experience! Management was top class, easy access to the hall plus accommodation was of elite level! Would recommend everyone.

                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Marriage hall in Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/03.jpg"
                        alt="Borivali West thread ceremony venue"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Raju Mal</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Good Experience with Classic villa party hall 👌
                    Overall the management Team is politely & Familiar
                    Thank For Wonderful Experience
                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    {/* <img
                      src="https://live.themewild.com/lovex/assets/img/shape/09.png"
                      alt="Reception hall Borivali West"
                    /> */}
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      {/* <img
                        src="https://live.themewild.com/lovex/assets/img/testimonial/04.jpg"
                        alt="Engagement hall in Borivali West"
                      /> */}
                    </div>
                    <div className="testimonial-author-info">
                      <h4> babu rao </h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Great staff Clean rooms are available here.
                  1. Ambiance :- Excellent.
                  2. Decoration Stage :- Excellent
                  3. AC condition :- Excellent
                  4. Chairs & other Facilities :- Excellent.

                    </p>
                    <div className="testimonial-quote-icon">
                      {/* <i className="flaticon-straight-quotes" /> */}
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* ======== About Instra ======== */}
      <div className="instagram-area">
        <div className="container-fluid px-0">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              570: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              990: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay]}
            className="homeInstagram"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service1} alt="img" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service2} alt="img" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service3} alt="Birthday party hall " />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service4} alt="Marriage hall in Borivali West" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service5} alt="img" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service6} alt="" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service7} alt="img" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                    <img src={Service5} alt="img" />
                    <a href="https://www.instagram.com/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
