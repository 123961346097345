import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdFoodBank } from "react-icons/md";
import { FaCarSide } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";

import MetaTags from 'react-meta-tags';
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";
// import about from "../Img/about.jpg";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import "../CSS/Home.css";
import Footer from "../Components/Footer";

import About from '../Img/banner/b11.webp';
import Background from '../Img/banner/b5.jpg';
// =========== Insta Img ======
import Service1 from "../Img/instaImg/naming-ceremony.webp";
import Service2 from "../Img/instaImg/birthday.jpg";
import Service3 from "../Img/instaImg/marriage.webp";
import Service4 from "../Img/instaImg/marriage.jpg";
import Service5 from "../Img/instaImg/ring.webp";
import Service6 from "../Img/instaImg/recep.jpg";
import Service7 from "../Img/instaImg/ring.jpg";
import Service8 from "../Img/instaImg/birthday.webp";
import babyShower from "../Img/instaImg/baby-shower.webp";
import mehandi from "../Img/instaImg/mehandi.webp";
import sangeet from "../Img/instaImg/sangeet.webp"
import meeting from "../Img/instaImg/meeting.webp"

// ====== Gallery Img =======

import Gallery3 from "../Img/gallery/gallery3.webp";
import Gallery11 from "../Img/gallery/gallery11.webp";
import banquent2 from "../Img/gallery/gallery10.webp";
import gallery5 from "../Img/gallery/gallery14.webp";
import $ from "jquery";
import ServicesData from "../Components/ServicesData";
import EventsData from "../Components/EventsData";

const Home = (props) => {

  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Classic Villa Party Hall Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Classic Villa Party Hall</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "classicvillapartyhall@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "classicvillapartyhall",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");
   };
  return (
    <>
    <MetaTags>
      <title>Party Hall in Kandivali | Classic  Party Hall</title>
        <meta title="Party Hall in Kandivali | Classic  Party Hall" />
        <meta
          name="description"
          content="Explore our exquisite Party Hall in Kandivali, offering a blend of elegance and versatility. Host memorable celebrations with our exceptional facilities."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://classicvillapartyhall.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Party Hall in Kandivali | Classic  Party Hall" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://classicvillapartyhall.com/"/>
        <meta property="og:description" content="Explore our exquisite Party Hall in Kandivali, offering a blend of elegance and versatility. Host memorable celebrations with our exceptional facilities." />
        <meta property="og:image" content="https://classicvillapartyhall.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <div className="hero-section">
        <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
          <SwiperSlide>
            <div className="homebanner1">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                        Banquet Hall
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Welcome to our party hall in kandivali, where moments become memories. We pride ourselves on creating elegant and unforgettable experiences for your special events. From weddings to corporate gatherings, trust us to turn your vision into reality at our exceptional venue.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-kandivali" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          {/* <a href="/About" className="theme-btn">
                            About More
                            <i class="fa-solid fa-arrow-right"></i>
                          </a> */}
                            <Link to="/best-banquet-near-kandivali" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          {/* <a href="/kitty-party" className="theme-btn theme-btn2">
                            Learn More
                            <i class="fa-solid fa-arrow-right"></i>
                          </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homebanner2">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                        Food Dining Area 
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Our food dining area is a gastronomic haven where culinary excellence meets exquisite ambiance. From intimate gatherings to grand celebrations, our carefully curated menu showcases a fusion of flavors prepared with precision and creativity. Our culinary artisans are committed to crafting memorable dining moments, ensuring each dish is a masterpiece.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-kandivali" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          <Link to="/best-banquet-near-kandivali" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="homebanner3">
              <div className="hero-single">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <div className="hero-content align-items-center">
                        <h6>We Are Skilled &amp; Expert</h6>
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".50s"
                          style={{ animationDelay: "0.5s" }}
                        >
                          Home Stay
                        </h2>
                        <p
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          className=""
                          style={{ animationDelay: "0.75s" }}
                        >
                        Welcome to our party hall in kandivali, where we redefine the concept of home stays within the grandeur of our banquet hall setting. Nestled in luxury, our home stay accommodations offer a blend of comfort and sophistication. Whether you're here for a special event or a leisure retreat, our well-appointed rooms provide a welcoming sanctuary.
                        </p>
                        <div
                          className="hero-btn"
                          data-animation="fadeInUp"
                          data-delay=".75s"
                          style={{ animationDelay: "0.75s" }}
                        >
                          <Link to="/best-banquet-kandivali" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                          <Link to="/best-banquet-near-kandivali" className="theme-btn theme-btn2">Learn More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* ========== Home About ============= */}
      <section className="about-area py-5 mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="about-left wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInLeft",
                }}
              >
                <div className="about-img">
                  <img
                    src={gallery5}
                    alt="Party Hall in Kandivali "
                  />
                </div>
                <div className="about-experience">
                  <h2>
                    10 <span>+</span>
                  </h2>
                  <span className="about-experience-text">
                    Years Of Experience
                  </span>
                </div>
                <div className="about-img-shape">
                  <img
                    src={About}
                    alt="Party Hall "
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-right wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".25s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h1 className="site-title">
                  <span> Party Hall in Kandivali </span>
                  </h1>
                </div>
                <p className="about-text">
                Introducing Classic villa party hall in kandivali, a premier venue with ac hall perfect for 75 to 250 people for discerning individuals seeking unparalleled elegance and seamless event execution. With a legacy of excellence, our banquet hall stands as a symbol of sophistication and class. From meticulously designed interiors to top-notch amenities, we offer a refined setting for weddings, corporate events, and social gatherings. Our professional team is dedicated to exceeding expectations, ensuring every event is executed with precision and flair. Discover a venue where professionalism meets perfection – Classic villa party hall in kandivali 
                </p>
                <div className="about-list-wrapper">
                  <ul className="about-list list-unstyled">
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                      <p>Premier venue, AC hall for 75-250 people.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                        <p>Meticulously designed interiors, top-notch amenities.</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-icon">
                        <span className="fa fa-check-circle" />
                      </div>
                      <div className="about-list-text">
                      <p>Professional team ensures precision, flair in execution.</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="about-bottom">
                <Link to="/best-banquet-kandivali" className="theme-btn">About More
                          <i class="fa-solid fa-arrow-right"></i>
                          </Link>
                  <div className="about-call">
                    <div className="about-call-icon">
                    <i className="fa fa-phone" />
                    </div>
                    <div className="about-call-content">
                      <span>Call Us Anytime</span>
                      <h5 className="about-call-number">
                        <a href="tel:+919987220795">+91 9987220795</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Home Services ============ */}
      <section className="service-area bg py-5">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay=".25s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.25s",
                animationName: "fadeInDown",
              }}
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Our Events</span>
                <h2 className="site-title">
                  Our Awesome Events
                </h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
            modules={[ Pagination]}
            className="homeService"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                    <img src={Service3} alt=" Hall in Kandivali "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#" style={{textDecoration:"none"}}>Wedding</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Embark on a journey of timeless romance at a party hall in kandivali. As a distinguished wedding venue, we specialize in turning dreams into reality. Our enchanting spaces, adorned with opulence, provide the perfect backdrop for your special day. With a commitment to meticulous planning and flawless execution, our experienced team ensures that every moment of your wedding is nothing short of magical.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={Service5} alt="Party Hall in Kandivali"/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Engagement</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Our party hall in kandivali sets the stage for unforgettable moments of love and commitment. From intimate gatherings to grand affairs, we specialize in curating the perfect ambiance for your engagement celebration. Our meticulous attention to detail ensures that your special day unfolds seamlessly, allowing you to focus on the joy of this significant milestone.
                    </p>
                    
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={Service8} alt="Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Birthday Party</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Our party hall in kandivali is the ideal canvas for creating unforgettable birthday memories. Whether it's a milestone celebration or an intimate gathering, our versatile spaces cater to your unique vision. Our dedicated team ensures every detail, from decor to entertainment, is tailored to your desires.
                    </p>
                    
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={Service1} alt="Party Hall in Kandivali"/>
                  </div>
                 
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Naming Ceremony</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Welcome to our Kandivali party hall for naming ceremonies, an elegant haven where the joy of new beginnings is celebrated with grace. Our banquet hall provides the ideal setting, blending tradition and contemporary elegance. Trust our dedicated team to craft a seamless event, ensuring every detail reflects the significance of this milestone.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={babyShower} alt="Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Baby Shower</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Explore our welcoming baby shower venue near Kandivali, where we specialize in crafting enchanting settings for joyous new beginnings. Our banquet hall, Classic Party Hall, promises a delightful and memorable experience with curated details, charming decor, and a warm atmosphere for intimate or grand celebrations. Celebrate parenthood with us, creating cherished memories filled with love and anticipation. Join us for a baby shower radiating joy, laughter, and the warmth of welcoming a new addition to your family.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={mehandi} alt=" Party Hall "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Mehandi</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Step into our enchanting Mehandi venue in Kandivali, where vibrant threads of tradition blend seamlessly with celebration. Our banquet hall, dedicated to Mehendi ceremonies, boasts intricate decor, personalized setups, and delectable catering. Trust us to make your special day memorable with vibrant colors, laughter, and shared celebrations.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
              <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={sangeet} alt=" Hall in Kandivali"/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Sangeet</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Experience the magic at our top party hall in Kandivali for Sangeet, where traditions blend with celebration. Our banquet hall, a canvas for joy, is meticulously designed with graceful decor for lively performances. Trust our dedicated team to curate every detail, ensuring your Sangeet is a harmonious blend of tradition and contemporary elegance. Celebrate the magic of new beginnings with laughter, dance, and the spirit of celebration at our top party hall in Kandivali for Sangeet.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="service-item">
                  <div className="service-img">
                  <img src={meeting} alt="Party in Kandivali "/>
                  </div>
                  <div className="service-content">
                    <h3 className="service-title">
                      <a href="#">Corporate events</a>
                    </h3>
                    <p className="service-text" id="style-4">
                    Discover our corporate event party hall in Kandivali, redefining sophistication and efficiency. Our versatile venue sets the stage for conferences, professional gatherings, and celebrations. With state-of-the-art facilities and a commitment to excellence, our experienced team ensures seamless execution, creating a productive and enjoyable experience. Elevate your corporate events at [Banquet Hall Name], where every detail is finely tuned for success in a refined ambiance.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            
          </Swiper>
        </div>
      </section>
      <div className="gallery-area py-5">
        
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mx-auto"
              data-wow-duration="1s"
              data-wow-delay=".25s"
            >
              <div className="site-heading text-center">
                <span className="site-title-tagline">Our Services</span>
                <h2 className="site-title">
                Discover what sets us apart
                </h2>
              </div>
            </div>
          </div>
          <div
            className="row popup-gallery"
            style={{
              position: "relative",
            }}
          >
           
            <div className="col-md-7">
            <div className="gallery-item1">
  <div className="gallery-img1">
    <div className="overlay">
    <Link to='/banquet-hall-social-events-kandivali'>
    <p className="overlay-title">Catering Services</p>
    </Link>
      <p className="overlay-subtitle">Exquisite culinary experiences for your events</p>
    </div>
    <img
      src="https://olivias-kitchen.co.uk/wp-content/uploads/2022/07/Thai-buffet.jpg"
      alt="Party  in Kandivali "
    />
  </div>
</div>
            </div>
            <div className="col-md-5">
              <div className="row">
              <div className="col-12">
  <div className="gallery-item">
    <div className="gallery-img">
      <div className="overlay">
      <Link to='/banquet-hall-social-events-kandivali'>
      <p className="overlay-title">Banquet Services</p>
      </Link>
        
        <p className="overlay-subtitle">Elegant venues for unforgettable gatherings</p>
      </div>
      <img src={banquent2} alt="  Hall in Kandivali" />
    </div>
   
  </div>
</div>

<div className="col-12">
  <div className="gallery-item">
    <div className="gallery-img">
      <div className="overlay">
      <Link to='/banquet-hall-social-events-kandivali'>
      <p className="overlay-title">Homestay Services</p>
      </Link>
        <p className="overlay-subtitle">Your home away from home</p>
      </div>
      <img src={Gallery3} alt=" Party Hall " />
    </div>
   
  </div>
</div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
     
      {/* ========= Home counter ========== */}
      <div className="counter-area my-5">
        <div className="container">
          <div className="counter-wrapper">
          <div className="site-heading text-center">
                <span className="site-title-tagline">Our amenities</span>
                <h2 className="site-title">
                Discover the extraordinary features that await you
                </h2>
              </div>
            <div className="row row-cols-lg-4 row-cols-2">
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                  <MdFoodBank />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title">Veg Food  </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    <FaUserAlt />

                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title"> upto 25 people Stay  </h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                    <FaCarSide />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title"> Parking</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="counter-box">
                  <div className="icon">
                  <FaPeopleGroup />
                  </div>
                  <div>
                    <span className="counter">
                    </span>
                    <h6 className="title">125 Sitting</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========== Home Quote-Area ===== */}
      <section className="quote-area py-5">
        <div className="container">
          <div className="quote-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="quote-img">
                  <img
                    src={Gallery11}
                    alt="Party Hall in Kandivali"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="quote-content">
                  <div className="quote-header">
                    <h6>Get Free Quote</h6>
                    <h2>Feel Free To Contact With Us</h2>
                  </div>
                  <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                onChange={(e) => setcustName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setCustEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="number"
                name="contact"
                placeholder="Your Contact Number"
                className="form-control"
                onChange={(e) => setCustContact(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <select
                className="form-control"
                name="service"
                onChange={(e) => setCustSubject(e.target.value)}
              >
                <option value="">Choose Service</option>
                {ServicesData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
                {EventsData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            cols={30}
            rows={5}
            className="form-control"
            placeholder="Write Your Message"
            onChange={(e) => setCustMessage(e.target.value)}
          />
        </div>
        {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="theme-btn" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Home Cta ========== */}
      <section className="cta-area pb-5">
        <div className="container">
          <div className="cta-wrapper">
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <div className="cta-text">
                  <h2>We Are Ready To Celebrate Your All Types Of Event</h2>
                  <p>
                  With meticulous attention to detail and a commitment to excellence, we create tailored experiences that reflect the uniqueness of each event.
                  </p>
                </div>
                <a href="tel:+919987220795" className="theme-btn mt-30" target="blank">
                  Contact Now <i className="fa-solid fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== Home Testimonal ========= */}
      <section className="testimonial-area bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="site-heading text-center">
                <span className="site-title-tagline">Testimonials</span>
                <h2 className="site-title">
                  What Our Client Say's 
                </h2>
              </div>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              570: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              990: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="homeTestimoial"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                    
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                   
                    </div>
                    <div className="testimonial-author-info">
                      <h4>vipul pai</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Pleasant stay. Spacious and clean rooms. Mr. Nitin  really helped us customise our stay duration according to our needs and was cordial. highly recommend this property for your next stay.
                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                    
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Gauresh Mahadik</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Excellent experience! Management was top class, easy access to the hall plus accommodation was of elite level! Would recommend everyone.

                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      
                    </div>
                    <div className="testimonial-author-info">
                      <h4>Raju Mal</h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Good Experience with Classic villa party hall 👌
                    Overall the management Team is politely & Familiar
                    Thank For Wonderful Experience
                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-single">
                  <div className="testimonial-shape">
                   
                  </div>
                  <div className="testimonial-content">
                    <div className="testimonial-author-img">
                      
                    </div>
                    <div className="testimonial-author-info">
                      <h4> babu rao </h4>
                      <p>Clients</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <p>
                    Great staff Clean rooms are available here.
                  1. Ambiance :- Excellent.
                  2. Decoration Stage :- Excellent
                  3. AC condition :- Excellent
                  4. Chairs & other Facilities :- Excellent.

                    </p>
                    <div className="testimonial-quote-icon">
                      <i class="fa-solid fa-quote-right"></i>
                    </div>
                  </div>
                  <div className="testimonial-rate">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* ======== Home Instra ======== */}
      <div className="instagram-area">
        <div className="container-fluid px-0">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              570: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              990: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay]}
            className="homeInstagram"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={Service1} alt="  Hall in Kandivali "/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={Service2} alt="Party  in Kandivali"/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={mehandi} alt="Party Hall "/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={Service4} alt="Party Hall  Kandivali"/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={meeting} alt="Party  in Kandivali"/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={Service6} alt="Party in Kandivali"/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="instagram-item">
                  <div className="instagram-img">
                  <img src={Service7} alt=" Hall in Kandivali"/>
                    <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
