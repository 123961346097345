import React, { useState, useCallback,useLayoutEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import  ProjectImages from "../Components/ServicesData";
import Footer from "../Components/Footer";
import "../CSS/Gallery.css";
import MetaTags from 'react-meta-tags';
import cv1 from "../Img/gallery/comprees-gallery/cv (1).webp";
import cv2 from "../Img/gallery/comprees-gallery/cv (2).webp";
import cv3 from "../Img/gallery/comprees-gallery/cv (3).webp";
import cv4 from "../Img/gallery/comprees-gallery/cv (4).webp";
import cv5 from "../Img/gallery/comprees-gallery/cv (5).webp";
import cv6 from "../Img/gallery/comprees-gallery/cv (6).webp";
import cv7 from "../Img/gallery/comprees-gallery/cv (7).webp";
import cv8 from "../Img/gallery/comprees-gallery/cv (8).webp";
import cv9 from "../Img/gallery/comprees-gallery/cv (9).webp";
import cv10 from "../Img/gallery/comprees-gallery/cv (10).webp";
import cv11 from "../Img/gallery/comprees-gallery/cv (11).webp";
import cv12 from "../Img/gallery/comprees-gallery/cv (12).webp";
import cv13 from "../Img/gallery/comprees-gallery/cv (13).webp";
import cv14 from "../Img/gallery/comprees-gallery/cv (14).webp";
import cv15 from "../Img/gallery/comprees-gallery/cv (15).webp";
import cv16 from "../Img/gallery/comprees-gallery/cv (16).webp";
import cv17 from "../Img/gallery/comprees-gallery/cv (17).webp";
import cv18 from "../Img/gallery/comprees-gallery/cv (18).webp";
import cv19 from "../Img/gallery/comprees-gallery/cv (19).webp";
import cv20 from "../Img/gallery/comprees-gallery/cv (20).webp";
import cv21 from "../Img/gallery/comprees-gallery/cv (21).webp";
import cv22 from "../Img/gallery/comprees-gallery/cv (22).webp";
import cv23 from "../Img/gallery/comprees-gallery/cv (23).webp";
const Gallerys = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    cv1, cv2, cv3, cv4, cv5, cv6, cv7, cv8, cv9, cv10,
    cv11, cv12, cv13, cv14, cv15, cv16, cv17, cv18, cv19, cv20,
    cv21, cv22, cv23
];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };



  ////end
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  return (
    <>
    <MetaTags>
      <title>Banquet Hall For Social Events in Kandivali | Classic Villa</title>
        <meta title="Banquet Hall For Social Events in Kandivali | Classic Villa" />
        <meta
          name="description"
          content="Discover the perfect venue for your social events in Kandivali. Our versatile banquet hall offers a welcoming ambiance for celebrations."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://classicvillapartyhall.com/banquet-hall-social-events-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Banquet Hall For Social Events in Kandivali | Classic Villa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://classicvillapartyhall.com/"/>
        <meta property="og:description" content="Discover the perfect venue for your social events in Kandivali. Our versatile banquet hall offers a welcoming ambiance for celebrations." />
        <meta property="og:image" content="https://classicvillapartyhall.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      {/* ========= Gallery Banner ====== */}
      <div className="GalleryBanner">
        <div className="container">
          <h1 className="GalleryBanner-title">Banquet Hall For Social Events in Kandivali</h1>
        </div>
      </div>
      <section className="smile-section">
        <div className="container">
             <div className="sec-title centered">
      <h2 className="text-center my-5">Photo Gallery</h2>
    </div>
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-3" key={index}>
           {/* <Bounce> */}
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image} alt="resort gallery" />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
          {/* </Bounce> */}
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section> 
      <p className="d-none">
      Welcome to our Gallery of banquet hall for social events in kandivali, a visual journey through the enchanting spaces and memorable moments hosted at classic villa banquet hall for social events in kandivali. Explore the elegance and versatility of our venue as captured through captivating images and glimpses of events that have graced our halls.

Immerse yourself in the grandeur of our banquet hall through the lens of celebrations—weddings, engagements, corporate events, and more. Witness the meticulous attention to detail in our decor, the seamless execution of events, and the joy radiating from each gathering.

Our Gallery showcases the unique ambiance of the banquet hall for social events in kandivali, offering a preview of the stunning backdrops, luxurious interiors, and the vibrant energy that defines our space. From intimate affairs to grand soirées, each image reflects the commitment to excellence that sets us apart.

As you peruse our Gallery, envision your own special moments taking center stage in our distinguished venue. We invite you to discover the beauty, sophistication, and timeless allure that banquet hall for social events in kandivali brings to every event. Join us on this visual odyssey and envision your celebration unfolding in the captivating surroundings of our exquisite banquet hall.

      </p>
      {/* =========== Gallery Section ===== */}
      {/* <section className="Gallery-area py-5">
        <div className="container">
          <Gallery photos={ProjectImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={ProjectImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section> */}
      {/* <section>
      <div className="container">
          
             
          <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
     {images.map((image, index) => (
       <div className="col mb-md-5 mb-3" key={index}>
         
         <div className="smile-img" onClick={() => openLightbox(index)}>
           <img src={image} alt="resort gallery" />
           <div className="smile-icon">
             <TiEyeOutline />
           </div>
         </div>
       
       </div>
     ))}
     {lightboxOpen && (
       <Lightbox
         mainSrc={images[photoIndex]}
         nextSrc={images[(photoIndex + 1) % images.length]}
         prevSrc={images[(photoIndex + images.length - 1) % images.length]}
         onCloseRequest={closeLightbox}
         onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
         onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
       />
     )}
   </div>
       </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Gallerys;
