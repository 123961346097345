import React, { useState } from "react";
import "../CSS/NavBar.css";
import "../CSS/CommonCss.css";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ServicesData from "./ServicesData";
import Logo from "../Img/Classic_Villa_logo.png";
import EnquiryModal from "../Components/EnquiryModal";
import EventsData from "../Components/EventsData";

const NavBar = () => {
  const [color, setColor] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // Add state for navbar toggle

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  const closeNavbar = () => {
    setIsNavbarOpen(false); // Close navbar when a link is clicked
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen); // Toggle navbar when the toggle button is clicked
  };

  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="container">
            <div className="header-top-wrapper">
              <div className="header-top-left">
                <div className="header-top-contact">
                  <ul>
                    <li>
                      <div className="header-top-contact-info">
                        <a href="tel:+91 9987220795" target="blank">
                          <i className="fa fa-phone" /> +91 9987220795
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="header-top-contact-info">
                        <a href="mailto:ajantagoregaon@gmail.com">
                          <i className="fa fa-envelope"></i>
                          classicvillapartyhall.com
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="header-top-contact-info">
                        <a href="#">
                          <i className="far fa-clock" /> Mon - Sun (09:00 am -
                          10:00 pm)
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-top-right">
                <div className="header-top-social">
                  <span>Follow Us:</span>
                  <a href="https://www.facebook.com/classicvillapartyhall/">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="https://www.instagram.com/classicvillapartyhall/">
                      <i className="fab fa-instagram" />
                    </a>
                  <a href="https://wa.me/+919987220795?txt"> 
                    <i className="fab fa-whatsapp" />
                  </a>
                  <a href="tel:+91 9987220795">
                    <i className="fa fa-phone" />
                  </a>
                  {/* <a href="#">
                    <i className="fab fa-linkedin-in" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-bar-component">
          <section className="NavSection">
            <Navbar
              collapseOnSelect
              expand="lg"
              variant="dark"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <Container>
                <Navbar.Brand href="/">
                  <Link to="/">
                    <div className="Logo-Container">
                      <img src={Logo} alt="Logo" />
                    </div>
                  </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-center"
                >
                  <Nav>
                  <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                  <Nav.Link ><Link to="/best-banquet-kandivali">About</Link></Nav.Link>
                    <button className="dropdownShow">
                      Events
                      <div className="dorpdown">
                        <div className="dropdown_list">
                          {EventsData.map((ele) => {
                            const { Header, Slugs } = ele;
                            return (
                              <>
                               <Nav.Link href="/"><Link to={`${Slugs}`}>{Header}</Link></Nav.Link>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </button>
                    <button className="dropdownShow">
                      Services
                      <div className="dorpdown">
                        <div className="dropdown_list">
                          {ServicesData.map((ele) => {
                            const { Header, Slugs } = ele;
                            return (
                              <>
                               <Nav.Link href="/"><Link to={`${Slugs}`}>{Header}</Link></Nav.Link>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </button>
                  <Nav.Link href="#"><Link to="/banquet-hall-social-events-kandivali">Gallery</Link></Nav.Link>
                  <Nav.Link href="#"><Link to="/best-banquet-near-kandivali">Contact Us</Link></Nav.Link>
                  </Nav>
                  <div class="header-btn">
                    <button
                      type="submit"
                      className="theme-btn"
                      onClick={() => setModalShow(true)}
                    >
                      Get Quote <i class="fa-solid fa-arrow-right"></i>
                    </button>
                    <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </section>
        </div>
      </header>
    </>
  );
};

export default NavBar;
