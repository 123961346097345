import React from "react";
import "../CSS/Footer.css";
import {FaAngleDoubleRight}from "react-icons/fa";
import ServicesData from "./ServicesData";
import SpecialityData from "./SpecialityData";
import { Link } from "react-router-dom";
// import Logo from "../Img/logo.png";
import Logo from "../Img/Classic_Villa_logo.png";
import EventsData from "./EventsData";

const Footer = () => {
  return (
    <>
      <footer className="footer-area">
        <div className="footer-widget">
          <div className="container">
            <div className="row footer-widget-wrapper py-5">
              <div className="col-md-5 col-sm-6">
                <div className="footer-widget-box about-us">
                  <a href="/" className="footer-logo">
                    <img src={Logo} alt="Logo" />
                  </a>
                  <p className="mb-4 mx-5">
                  Elegant banquet hall for memorable events. Perfect for weddings, corporate gatherings, and celebrations. Meticulous decor and personalized services ensure a seamless and memorable experience. Host your special occasions with us.
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-sm-6">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Quick Links</h4>
                  <ul className="footer-list">
                    <li>
                    <Link to="/"><i><FaAngleDoubleRight/></i>Home</Link>
                    </li>
                    <li>
                    <Link to="/About"><i><FaAngleDoubleRight/></i>About Us</Link>
                    </li>
                    <li>
                    <Link to="/baby-shower"><i><FaAngleDoubleRight/></i>Services</Link>
                    </li>
                    <li>
                    <Link to="/Gallerys"><i><FaAngleDoubleRight/></i>Gallery</Link>
                    </li>
                    <li>
                    <Link to="/Contact"><i><FaAngleDoubleRight/></i>Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2 col-sm-6">
                <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Our Services</h4>
                  <ul className="row row-cols-md-1 footer-list list-unstyled">
                  {ServicesData.map((ele, index) => {
                              const { Header, Slugs } = ele;
                              return (
                                <>
                                <li key={index} className="">
                                <Link to={`${Slugs}`} className="d-flex align-items-center">
                                  <i><FaAngleDoubleRight/></i>
                                  {Header}
                                </Link>
                              </li>
                                </>
                              );
                            })}
                  </ul>
                </div>
                {/* <div className="footer-widget-box list">
                  <h4 className="footer-widget-title">Our Events</h4>
                  <ul className="row row-cols-md-1 footer-list list-unstyled">
                  {EventsData.map((ele, index) => {
                              const { Header, Slugs } = ele;
                              return (
                                <>
                                <li key={index} className="">
                                <Link to={`${Slugs}`} className="d-flex align-items-center">
                                  <i><FaAngleDoubleRight/></i>
                                  {Header}
                                </Link>
                              </li>
                                </>
                              );
                            })}
                  </ul>
                </div> */}
              </div>
              <div className="col-md-3 col-sm-6">
              <div className="footer-widget-box about-us">
                <h4 className="footer-widget-title"> Location</h4>
                  <ul className="footer-contact">
                    <li>
                    <i className="fa fa-phone" />
                      <a href="tel:+919987220795">
                        +91 9987220795
                      </a> <br/>
                      
                    </li>
                    <li>
                    <i className="fa fa-phone d-hidden" />
                    <a href="tel:+919004939714">
                        +91 9004939714
                      </a>
                    </li>
                    <li>
                    <i className="fa fa-phone" />
                    <a href="tel:+919322671269">
                        +91 9322671269
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-map-marker-alt" />
                      Plot no. 91, RSC 15, Sec-09, Charkop, Kandivali West, Mumbai, Maharashtra 400067, India
                    </li>
                    <li>
                    <i className="far fa-envelope" />
                    <a href="mailto:classicvillapartyhall@gmail.com">
                        {/* <i className="far fa-envelope" /> */}
                        classicvillapartyhall@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
               
              </div>
            </div>
           
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p className="copyright-text">
                  © Copyright <span id="date">2024</span>{" "}
                  <a href="https://ajantapartyhall.in/">Classic Banquet Hall </a> All Rights Reserved.
                </p>
              </div>
              <div className="col-md-6">
                <p className="develop-text">
                 Develope By 
                  <a href="https://skdm.in/" className="ps-2"> Shree Krishana Digital Marketing.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
