import React, { useState,useRef } from "react";
import "../CSS/Contact.css";
import Footer from "../Components/Footer";
import $ from "jquery";
import ServicesData from "../Components/ServicesData";
import MetaTags from 'react-meta-tags';
import EventsData from "../Components/EventsData";

const Contact = (props) => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custContact || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Classic Villa Party Hall Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">${custContact}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Classic Villa Party Hall</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "classicvillapartyhall@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "classicvillapartyhall",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");

  };

  return (
    <>
      {/* ========= Contact Banner ====== */}
      <MetaTags>
      <title>Best Banquet near Kandivali | Classic Villa Party Hall</title>
        <meta title="Best Banquet near Kandivali | Classic Villa Party Hall" />
        <meta
          name="description"
          content="Elevate your celebrations at the Best Banquet near Kandivali. Immerse yourself in unparalleled luxury and impeccable service."
        />
        <meta
          name="keywords"
          content="Party Hall in Kandivali,Best Banquet in Kandivali,Party Hall For Events in Kandivali,Best Party Hall in Kandivali For Weddings,Banquet for Engagement in Kandivali,Birthday Party Hall in Kandivali,Party Hall in Kandivali for Naming Ceremony, Baby Shower Venues & Halls near Kandivali , Mehendi venues in Kandivali,Top Party Hall in Kandivali for Sangeet,Party Hall for Corporate Event in Kandivali,Party Hall with Stay in Kandivali,Best Banquet Hall in Kandivali,Top Banquet near Kandivali,Banquet Hall with Homestay in Kandivali,Banquet Halls with Catering in Kandivali,Banquet Hall For Social Events in Kandivali,Best Banquet near Kandivali"
        />
        <link rel="canonical" href="https://classicvillapartyhall.com/best-banquet-near-kandivali" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Banquet near Kandivali | Classic Villa Party Hall" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://classicvillapartyhall.com/best-banquet-near-kandivali"/>
        <meta property="og:description" content="Elevate your celebrations at the Best Banquet near Kandivali. Immerse yourself in unparalleled luxury and impeccable service." />
        <meta property="og:image" content="https://classicvillapartyhall.com/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <p className="d-none">
          Welcome to our best banquet near kandivali your gateway to extraordinary events and seamless celebrations. If you have inquiries, requests, or if you're ready to embark on your event planning journey with us, the Contact Us page is your direct link to personalized assistance.

Our dedicated team at our best banquet near kandivali, stands ready to assist you at every step of the way. Whether you're envisioning a dream wedding, corporate event, or any special celebration, we are here to turn your ideas into reality. Reach out to us via the provided contact information, and let us know how we can tailor our services to meet your specific needs.

Our commitment to excellence extends to our communication with you. Expect a prompt and courteous response as we address your queries and guide you through the process of creating an unforgettable event at [Banquet Hall Name].

Thank you for considering the best banquet near kandivali for your special occasions. We look forward to connecting with you and being a part of your journey to hosting memorable events that leave a lasting impression. Contact us today, and let the planning for your extraordinary celebration begin.

          </p>
      <div className="ContactBanner">
        <div className="container">
          <h1 className="ContactBanner-title">
          Best Banquet near Kandivali
          </h1>
        </div>
      </div>
      
      {/* ========= Contact- Form ====== */}
      <div className="contact-area mt-5 mb-5">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Get In Touch</h2>
                 
                  </div>
                  <form
        method="post"
        ref={formRef}
        action=""
        id="contact-form"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Your Name"
                onChange={(e) => setcustName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                onChange={(e) => setCustEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <input
                type="number"
                name="contact"
                placeholder="Your Contact Number"
                className="form-control"
                onChange={(e) => setCustContact(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <select
                className="form-control"
                name="service"
                onChange={(e) => setCustSubject(e.target.value)}
              >
                <option value="">Choose Service</option>
                {ServicesData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
                {EventsData.map((v, i) => (
                  <option key={i} value={v.Header}>
                    {v.Header}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <textarea
            name="message"
            cols={30}
            rows={5}
            className="form-control"
            placeholder="Write Your Message"
            onChange={(e) => setCustMessage(e.target.value)}
          />
        </div>
        {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
        <button type="submit" className="theme-btn" onClick={ContactEnquiry}>
          Send Message <i className="far fa-paper-plane" />
        </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========= Contact-Area ====== */}
      <div className="contact-area mt-5">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-content">
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i className="fa fa-map-marker-alt" />
                    </div>
                    <div className="contact-info-content">
                      <h5>Location</h5>
                      <p>
                      Plot no. 91, RSC 15, Sec-09, Charkop, Kandivali West, Mumbai, Maharashtra 400067, India
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="contact-info-content">
                      <h5>Call Us</h5>
                      <p>
                        <a href="tel:+91 9987220795">+91 99872 20795</a>
                      </p>
                      <p>
                        <a href="tel:+91 9004939714">+91 90049 39714</a>
                      </p>
                      <p>
                        <a href="tel:+91 9322671269">+91 93226 71269</a>
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="contact-info-content">
                      <h5>Email Us</h5>
                      <p>
                        <a href="mailto:classicvillapartyhall@gmail.com">
                        classicvillapartyhall@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="contact-info-icon">
                      <i className="fa fa-clock" />
                    </div>
                    <div className="contact-info-content">
                      <h5>Open Time</h5>
                      <p>Mon - Sun (09:00AM - 10:00PM)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 align-self-center">
                <div className="contact-map">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3768.657754906519!2d72.84325507520758!3d19.16645278205686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDA5JzU5LjIiTiA3MsKwNTAnNDUuMCJF!5e0!3m2!1sen!2sin!4v1694517625977!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe> */}
                <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15069.821375521977!2d72.8218665!3d19.2189772!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b795613db853%3A0xdc68e3df599fa254!2sCLASSIC%20VILLA%20PARTY%20HALL%20%26%20HOME%20STAY!5e0!3m2!1sen!2sin!4v1706076240801!5m2!1sen!2sin"
  // width={600}
  // height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <Footer />
    </>
  );
};

export default Contact;
