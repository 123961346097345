import Gallery1 from "../Img/Service/kity-party.png";
import Gallery2 from "../Img/Service/meeting.jpg";
import Gallery3 from "../Img/Service/thread.jpg";
import Gallery4 from "../Img/instaImg/marriage.webp";
import Gallery5 from "../Img/instaImg/ring.webp";
import Gallery6 from "../Img/instaImg/birthday.webp";
import namingCeremony from "../Img/instaImg/naming-ceremony.webp";
import babyShower from "../Img/instaImg/baby-shower.webp";
// import mehandi from "../img/instaImg/mehandii.webp";
import sangeet from "../Img/instaImg/sangeet.webp";
import corporateEvent from "../Img/instaImg/meeting.webp";
import homeStay from "../Img/gallery/gallery3.webp";
import mehandi from "../Img/instaImg/mehandi.webp";

const EventsData = [
  {
    Header: "WEDDING",
    Slugs: "/best-party-hall-kandivali-weddings",
    title:"Best Party Hall in Kandivali For Weddings | Classic Villa",
    description:"Discover the epitome of elegance and celebration at the Best Party Hall in Kandivali for weddings. Let your special day unfold in grandeur.",
    keyword:"Best Party Hall in Kandivali For Weddings",
    ServicesImage:Gallery4,
    SercicesHeading1:"Wedding",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the best party hall for event in kandivali for weddings, where dreams find a timeless backdrop for the most cherished day of your life. As a premier wedding venue, we pride ourselves on offering an enchanting space where love stories come to life. Our exquisite banquet hall, adorned with sophistication, serves as the perfect canvas for your celebration of love. From intimate ceremonies to grand receptions, our experienced team is dedicated to crafting an atmosphere that mirrors your vision. Meticulous planning and attention to detail are at the heart of our commitment to ensuring your wedding day unfolds seamlessly.",
    SercicesPara2:"Immerse yourself in the luxurious ambiance of the best party hall for event in kandivali for weddings, where every corner exudes romance and refinement. Our expert staff works tirelessly to bring your dreams to reality, creating a day that surpasses expectations. Whether it's a fairy-tale wedding or a modern celebration, we take pride in transforming your moments into lasting memories. Choose the best party hall for event in kandivali for weddings for experience that combines elegance, warmth, and the joy of beginning a beautiful journey together.",
    headingPoint1:"Customized Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Ample Parking:",
    headingPoint5:"Experienced Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"We offer customizable decoration options to match your baby shower theme, ensuring a visually stunning and Instagram-worthy backdrop for your celebration.",
    ServicesPoint2:"Our in-house catering team provides a wide array of culinary delights, from traditional favorites to modern twists, all prepared with the freshest ingredients to delight your taste buds.",
    ServicesPoint3:"We offer advanced audio-visual equipment to enhance your event, whether it's for showcasing cherished memories or adding a musical touch to your celebration.",
    ServicesPoint4:"We understand the importance of convenience, which is why we provide ample parking space to accommodate your guests comfortably.",
    ServicesPoint5:"Our professional and courteous staff is dedicated to ensuring that every detail of your baby shower is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint6:"Our venue is wheelchair accessible, ensuring that all your guests can comfortably join in the celebration.",
  },
  {
    Header: "ENGAGEMENT",
    Slugs: "/banquet-engagement-kandivali",
    title:"Banquet for Engagement in Kandivali | Classic Party Hall",
    description:"Celebrate love in style with our exquisite Banquet for Engagement in Kandivali. Discover a perfect blend of romance & sophistication creating lifetime memories.",
    keyword:"Banquet for Engagement in Kandivali",
    ServicesImage:Gallery5,
    SercicesHeading1:"Engagement",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to banquet for engagement in kandivali, the premier destination where love blossoms and engagements become enchanting celebrations. Nestled in an atmosphere of sophistication, our banquet hall is designed to be the perfect canvas for your special day of commitment. From intimate engagements to grand soirées, we take pride in curating a space that mirrors the unique essence of your love story.",
    SercicesPara2:"At the banquet for engagement in kandivali, our commitment to excellence ensures that your engagement celebration is a seamless blend of elegance and joy. Our experienced team is dedicated to transforming your vision into reality, meticulously planning every detail to create a memorable event. The ambiance is set with exquisite decor, and our attentive staff is at your service to make your engagement day truly extraordinary.Step into a world of romance and celebration at our banquet for engagement in kandivali, where each corner is infused with the promise of a beautiful journey ahead. Trust us to craft an engagement experience that captures the magic of the moment and marks the beginning of a lifetime of love. Choose our banquet for engagement in kandivali for an engagement celebration that reflects your unique story with grace and sophistication.    ",
    headingPoint1:"Creative Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that align with your birthday theme, making every detail count.",
    ServicesPoint2:"Our Both branch offers a diverse menu prepared by our culinary experts, catering to a variety of tastes and preferences.",
    ServicesPoint3:"Cutting-edge audio-visual equipment is available for entertainment, presentations, and music, enhancing the overall experience.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:"Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, so you can focus on enjoying your celebration.",
    ServicesPoint6:"Just like our Borivali branch, our Goregaon branch is wheelchair accessible, ensuring inclusivity.",
  },
  {
    Header: "BIRTHDAY PARTY",
    Slugs: "/kandivali-birthday-party-hall",
    title:"Birthday Party Hall in Kandivali | Classic Party Hall",
    description:"Make every moment magical with our Birthday Party Hall in Kandivali. Discover a perfect blend of ambiance and services for an unforgettable celebration.",
    keyword:"Birthday Party Hall in Kandivali",
    ServicesImage:Gallery6,
    SercicesHeading1:"Birthday Party",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our birthday party hall in kandivali, where we specialize in transforming birthday celebrations into unforgettable moments of joy and festivity. Our banquet hall provides the perfect canvas for marking milestones, creating memories, and reveling in the joy of birthdays. Whether it's a milestone event, a surprise party, or an intimate gathering, we pride ourselves on offering a versatile and welcoming space for every occasion.",
    SercicesPara2:"At our birthday party hall in kandivali our commitment to excellence ensures that your birthday party is a seamless blend of excitement and sophistication. Our dedicated team takes care of every detail, from personalized decor to delectable catering, allowing you to focus on celebrating with loved ones. The ambiance is infused with the spirit of celebration, making your birthday a truly special and memorable experience. Choose our birthday party hall in kandivali for a birthday celebration that goes beyond the ordinary – a place where each moment is crafted with care and flair. Let us be the host for your next birthday, and together, we'll create an atmosphere filled with laughter, joy, and the warmth of shared celebrations. Join us at our Birthday Party Hall In Kandivali  that reflects your style and creates lasting memories.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Experienced Staff:",
    headingPoint4:"Audio-Visual Setup:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that capture the essence of your engagement, creating a magical ambiance.",
    ServicesPoint2:"Our in-house culinary experts prepare a delectable range of dishes, from traditional favorites to modern delicacies, ensuring that your guests are treated to a memorable dining experience.",
    ServicesPoint3:"Our professional staff in Borivali is committed to ensuring that every detail of your engagement is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint4:"We offer state-of-the-art audio-visual equipment to enhance your celebration, including sound systems and projectors for speeches and presentations.",
    ServicesPoint5:"Convenient parking facilities are available to accommodate your guests comfortably.",
    ServicesPoint6:"We prioritize air conditioners, exhaustion fans, dais/stage, lighting systems, sound & music systems, fire extinguishers, food serving, dining facilities, welcome carpets, and private cabins.",
  },
  {
    Header: "NAMING CEREMONY",
    Slugs: "/party-hall-kandivali-naming-ceremony",
    title:"Party Hall in Kandivali for Naming Ceremony  | Classic Villa ",
    description:"Celebrate the joy of a Naming Ceremony at our sophisticated Party Hall in Kandivali. Immerse your special day in elegance.",
    keyword:"Party Hall in Kandivali for Naming Ceremony",
    ServicesImage:namingCeremony,
    SercicesHeading1:"Naming Ceremony",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our party hall in kandivali for naming ceremony a haven of elegance where the joy of new beginnings is celebrated with grace and warmth. Our banquet hall is the ideal setting for your precious naming ceremony, as we embrace the significance of introducing your bundle of joy to family and friends. At Classic Party Hall, we take pride in providing a sophisticated and intimate space that mirrors the joy and love surrounding this special occasion.",
    SercicesPara2:"Our commitment to excellence ensures that your naming ceremony is a seamless blend of tradition and contemporary elegance. The ambiance is adorned with thoughtful decor, creating an inviting atmosphere for your cherished guests. Our experienced team is dedicated to crafting an event that honors the significance of this milestone in your family's journey.Celebrate the beauty of life's early moments at the party hall in kandivali for naming ceremony where each detail is meticulously handled, allowing you to focus on the joy of sharing your blessings. Trust us to make your naming ceremony a memorable event, filled with love, laughter, and the warmth of family and friends. Join us at the party hall in kandivali for naming ceremony to create timeless memories as you welcome your little one into the embrace of love and celebration.",
    headingPoint1:"Bespoke Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Dedicated and Attentive Staff:",
    headingPoint6:"Inclusivity:",
    ServicesPoint1:"Our talented decorators work closely with you to create a visually stunning atmosphere that embodies the essence of your love story.",
    ServicesPoint2:"Our in-house culinary team comprises skilled chefs who are passionate about creating a culinary journey that resonates with your palate and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from top-tier sound systems for crystal-clear vows to dazzling visual displays that enhance your reception's ambiance.",
    ServicesPoint4:"Our seasoned wedding coordinators are not just professionals; they are partners in ensuring that every element of your special day unfolds seamlessly, allowing you to fully immerse yourself in the celebration.",
    ServicesPoint5:"Our passionate and courteous staff is dedicated to ensuring that your marriage celebration is executed flawlessly, from the initial setup to the final moments of your event.",
    ServicesPoint6:"We prioritize inclusivity by offering wheelchair accessibility, ensuring that all your loved ones can participate fully in the joy of your union.",
  },
  {
    Header: "BABY SHOWER",
    Slugs: "/baby-shower-venues-halls-kandivali",
    title:"Baby Shower Venues & Halls near Kandivali  | Classic Villa",
    description:"Create lasting memories at our Baby Shower Venues & Halls near Kandivali. Explore elegant spaces and personalized services for a joy-filled celebration.",
    keyword:" Baby Shower Venues & Halls near Kandivali ",
    ServicesImage:babyShower,
    SercicesHeading1:"Baby Shower",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our baby shower venue and hall near kandivali where we embrace the joy of new beginnings and specialize in creating enchanting settings for baby showers. Our banquet hall is a canvas for celebrating the imminent arrival of your little one with style and elegance. At Classic Party Hall, we understand the significance of this cherished occasion and strive to make your baby shower a delightful and memorable experience.",
    SercicesPara2:"With a commitment to excellence, our team at baby shower venue and hall near kandivali ensures that every detail of your baby shower is thoughtfully curated. From charming decor to delectable treats, we provide a warm and inviting atmosphere for you and your guests. Our spacious and versatile venue allows for both intimate gatherings and grand celebrations, accommodating your unique preferences.Celebrate the journey into parenthood at baby shower venue and hall near kandivali where each moment is infused with the love and anticipation of the special arrival. Trust us to create a baby shower that reflects your vision, creating beautiful memories that will be cherished for a lifetime. Join us at Classic Party Hall for a baby shower that radiates joy, laughter, and the warmth of welcoming a new addition to your family.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "MEHANDI",
    Slugs: "/mehendi-venues-kandivali",
    title:"  Mehendi venues in Kandivali | Classic  Party Hall",
    description:"Celebrate love in style with our exquisite Banquet for Engagement in Kandivali. Discover a perfect blend of romance & sophistication creating lifetime memories.",
    keyword:" Mehendi venues in Kandivali",
    ServicesImage:mehandi,
    SercicesHeading1:"Mehandi",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our Mehandi venue in kandivali where we weave the vibrant threads of tradition and celebration into the fabric of your Mehendi ceremony. Our banquet hall provides a stunning backdrop for this joyful pre-wedding event, dedicated to the artistry of henna and the celebration of love. At Mehandi venue in kandivali, we understand the cultural significance and exuberance surrounding Mehendi ceremonies, and we are honored to be part of these cherished moments.",
    SercicesPara2:"Step into our enchanting venue, adorned with intricate and colorful decor, creating the perfect ambiance for the Mehendi festivities. Our dedicated team ensures a seamless and delightful experience, from personalized setups to catering that caters to your tastes.Celebrate the beauty of this cultural tradition at Mehandi venue in kandivali, where each detail is carefully considered to enhance the spirit of your Mehendi ceremony. Our commitment to excellence ensures that your special day is filled with vibrant colors, laughter, and the warmth of shared celebrations. Trust us to make your Mehendi ceremony a memorable and culturally rich experience for you and your loved ones. Join us at Mehandi venue in kandivali to celebrate love, art, and the joy of new beginnings.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "SANGEET",
    Slugs: "/top-party-hall-kandivali-sangeet",
    title:"Top Party Hall in Kandivali for Sangeet | Classic Party Hall",
    description:"Elevate your Sangeet ceremony at the Top Party all in Kandivali. Unmatched ambiance, impeccable service, and unforgettable moments await.",
    keyword:"Top Party Hall in Kandivali for Sangeet",
    ServicesImage:sangeet,
    SercicesHeading1:"Sangeet",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our top party hall in kandivali for sangeet, where we set the stage for enchanting Sangeet ceremonies, blending music, dance, and celebration into a symphony of joy. Our banquet hall is a canvas where traditions come alive, and love is celebrated with fervor and elegance. At the top party hall in kandivali for sangeet, we recognize the cultural significance of Sangeet ceremonies and take pride in providing a captivating venue to enhance this musical celebration.",
    SercicesPara2:"Step into our meticulously designed space, adorned with graceful decor and equipped to host lively performances. Our experienced team is dedicated to ensuring that every detail of your Sangeet ceremony is curated with precision, allowing you to revel in the festivities with your loved ones.Embrace the magic of this pre-wedding celebration at the top party hall in kandivali for sangeet where each note of music resonates with the joy of new beginnings. Our commitment to excellence ensures that your Sangeet ceremony is a harmonious blend of tradition and modernity, creating an atmosphere where cultural richness meets contemporary elegance. Trust us to make your Sangeet ceremony a memorable and joyous occasion, filled with laughter, dance, and the spirit of celebration. Join us at the top party hall in kandivali for sangeet to create timeless memories as you embark on the journey to marital bliss.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "CORPORATE EVENTS",
    Slugs: "/party-hall-corporate-event-kandivali",
    title:"Party Hall for Corporate Event in Kandivali | Classic Villa ",
    description:"Host your next corporate event in style at our exquisite Party Hall in Kandivali. Unparalleled facilities, elegant ambiance, and seamless services.",
    keyword:"Party Hall for Corporate Event in Kandivali",
    ServicesImage:corporateEvent,
    SercicesHeading1:"Corporate Events",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our party hall for corporate event in kandivali where we redefine corporate events with sophistication and efficiency. Our versatile banquet hall provides the perfect setting for professional gatherings, conferences, and corporate celebrations. At  our party hall for corporate event in kandivali we understand the importance of creating a conducive environment that reflects the professionalism and vision of your organization.",
    SercicesPara2:"With state-of-the-art facilities and a commitment to excellence, our venue is designed to meet the diverse needs of corporate events. Whether it's a product launch, annual meeting, or team-building event, our experienced team ensures seamless execution, from audio-visual arrangements to customizable layouts.Step into a space where business meets elegance at our party hall for corporate event in kandivali .Our attentive staff is dedicated to providing top-notch service, catering to your specific requirements and ensuring a productive and enjoyable experience for you and your guests. Elevate your corporate events at Classic Party Hall, where efficiency meets sophistication, and every detail is finely tuned for success. Trust us to make your professional gatherings not only productive but also memorable in the refined ambiance of our banquet hall.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
  {
    Header: "STAY FOR GUEST",
    Slugs: "/party-hall-stay-kandivali",
    title:"Party Hall with Stay in Kandivali | Classic Villa Party Hall",
    description:"Make your celebrations extraordinary with our Party Hall With Stay in Kandivali. Seamlessly transition from events to unforgettable nights in comfort.",
    keyword:"Party Hall with Stay in Kandivali",
    ServicesImage:homeStay,
    SercicesHeading1:"Stay For Guest",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to the party hall with stay in kandivali, with home stay facility with 4 ac rooms capacity upto 25 people, where we extend our hospitality beyond events, offering a comfortable and inviting stay for your guests. Our banquet hall is not just a venue; it's a destination where convenience and elegance meet. At Classic Party Hall, we understand the importance of providing a seamless and enjoyable experience for your guests, whether attending a wedding, corporate event, or any special celebration.",
    SercicesPara2:"Our accommodation facilities are designed to be a home away from home, providing a retreat within the elegant surroundings of our banquet hall. From well-appointed rooms to personalized services, we ensure that your guests' stay is as memorable and pleasant as the event itself. Our attentive staff is dedicated to catering to every need, making sure your guests feel welcomed and valued.Choose our party hall with stay in kandivali for an integrated experience where the event and accommodation seamlessly align. Trust us to be the host not only for your celebrations but also for the comfort and convenience of your guests. Join us at our party hall with stay in kandivali where every stay is a delightful extension of our commitment to exceptional hospitality.",
    headingPoint1:"Personalized Decor:",
    headingPoint2:"Culinary Excellence:",
    headingPoint3:"Cutting-Edge Technology:",
    headingPoint4:"Personalized Event Coordination:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Dedicated and Attentive Staff:",
    ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
    ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
    ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
    ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
    ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
    ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  },
];

export default EventsData;
