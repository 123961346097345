import Gallery1 from "../Img/Service/kity-party.png";
import Gallery2 from "../Img/Service/meeting.jpg";
import Gallery3 from "../Img/Service/thread.jpg";
import Gallery4 from "../Img/gallery/gallery10.webp";
import Gallery5 from "../Img/gallery/gallery3.webp";
import Gallery6 from "../Img/gallery/Thai-buffet.webp";
import Gallery7 from "../Img/Service/marriage.jpg";
import Gallery8 from "../Img/Service/recep.jpg";

const ServicesData = [
  {
    Header: "Banquet",
    Slugs: "/top-banquet-near-kandivali",
    description:"Elevate your events at the Top Banquet near Kandivali. Experience unparalleled elegance and hospitality as we ensure your celebrations become extraordinary.",
    title:"Top Banquet near Kandivali | Classic Party Hall",
    keyword:"Top Banquet near Kandivali",
    ServicesImage:Gallery4,
    SercicesHeading1:"Banquent",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our top banquet hall near kandivali where moments are crafted into memories and celebrations are elevated to new heights. Our banquet hall stands as a testament to elegance, sophistication, and impeccable service. Nestled in a refined ambiance, we are dedicated to providing a versatile and exquisite space for a myriad of events.At the top banquet hall near kandivali ,we understand the significance of your celebrations, be it weddings, engagements, corporate events, or special occasions. Our commitment to excellence is reflected in the meticulous attention to detail and the seamless execution of every event. From breathtaking decor to state-of-the-art facilities, our venue is designed to cater to the unique needs of your special day.",
    SercicesPara2:"Our experienced and professional team takes pride in ensuring that each event hosted at top banquet hall near kandivali surpasses expectations. We offer a harmonious blend of aesthetics, comfort, and personalized service, creating an atmosphere where celebrations come to life.Choose Classic Villa Party Hall for a venue that goes beyond expectations, where your vision is transformed into a reality. Join us in creating unforgettable moments in the enchanting embrace of top banquet hall near kandivali where every event is a masterpiece.",
    headingPoint1:"Customized Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Ample Parking:",
    headingPoint5:"Experienced Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"We offer customizable decoration options to match your baby shower theme, ensuring a visually stunning and Instagram-worthy backdrop for your celebration.",
    ServicesPoint2:"Our in-house catering team provides a wide array of culinary delights, from traditional favorites to modern twists, all prepared with the freshest ingredients to delight your taste buds.",
    ServicesPoint3:"We offer advanced audio-visual equipment to enhance your event, whether it's for showcasing cherished memories or adding a musical touch to your celebration.",
    ServicesPoint4:"We understand the importance of convenience, which is why we provide ample parking space to accommodate your guests comfortably.",
    ServicesPoint5:"Our professional and courteous staff is dedicated to ensuring that every detail of your baby shower is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint6:"Our venue is wheelchair accessible, ensuring that all your guests can comfortably join in the celebration.",
  },
  {
    Header: "Home Stay",
    Slugs: "/banquet-hall-homestay-kandivali",
    description:"Experience the perfect combination of celebration & comfort with our Banquet Hall With  Homestay in Kandivali. Host memorable events and enjoy a cozy stay.",
    title:"Banquet Hall with Homestay in Kandivali  | Classic Party Hall ",
    keyword:"Banquet Hall with Homestay in Kandivali",
    ServicesImage:Gallery5,
    SercicesHeading1:"Home Stay",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our banquet hall with home stay in kandivali a unique destination where the comfort of home meets the grandeur of our banquet hall setting. At  banquet hall with home stay in kandivaliwe redefine hospitality by offering a distinctive home-away-from-home experience within our elegant venue. Our well-appointed rooms provide a cozy retreat, combining modern amenities with a touch of luxury.Nestled in the heart of kandivali, our home stay accommodations cater to various needs, from business travelers to event attendees. Whether you're here for a celebration hosted in our banquet hall or seeking a comfortable stay in the city,  banquet hall with home stay in kandivali is designed to exceed your expectations.",
    SercicesPara2:"Our commitment to exceptional service ensures that your stay is not only comfortable but also memorable. From personalized attention to thoughtful details, we strive to create an atmosphere that resonates with warmth and hospitality. Enjoy the convenience of staying on-site, where the elegance of our banquet hall seamlessly extends into your lodging experience.Choose our banquet hall with home stay in kandivali for a stay that harmoniously combines the comforts of home with the sophistication of our venue. Join us in creating lasting memories in the heart of kandivali, where every visit is an invitation to experience the perfect blend of hospitality and refinement.",
    headingPoint1:"Creative Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Audio-Visual Setup:",
    headingPoint4:"Convenient Parking:",
    headingPoint5:"Efficient Staff:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that align with your birthday theme, making every detail count.",
    ServicesPoint2:"Our Both branch offers a diverse menu prepared by our culinary experts, catering to a variety of tastes and preferences.",
    ServicesPoint3:"Cutting-edge audio-visual equipment is available for entertainment, presentations, and music, enhancing the overall experience.",
    ServicesPoint4:"Ample parking space is provided to ensure the comfort of your guests.",
    ServicesPoint5:"Our experienced staff in Goregaon takes care of every detail, from setup to cleanup, so you can focus on enjoying your celebration.",
    ServicesPoint6:"Just like our Borivali branch, our Goregaon branch is wheelchair accessible, ensuring inclusivity.",
  },
  {
    Header: "Catering",
    Slugs: "/banquet-halls-catering-kandivali",
    description:"Discover the finest Banquet Halls with Catering Services in Kandivali. Elevate your celebrations with our exceptional venues, perfect for weddings.",
    title:"Banquet Halls with Catering in Kandivali | Classic Party Hall ",
    keyword:"Banquet Halls with Catering in Kandivali",
    ServicesImage:Gallery6,
    SercicesHeading1:"Catering",
    SercicesHeading2:"Service Features",
    SercicesPara1:"Welcome to our banquet hall with catering in kandivali where culinary artistry meets exceptional service. As a premier catering company, we take pride in curating unforgettable dining experiences for a diverse range of events. Our commitment to excellence is reflected in the meticulous selection of fresh, high-quality ingredients and the dedication of our skilled chefs to craft dishes that tantalize the taste buds.At the banquet hall with catering in kandivali we understand the significance of your events, whether it's a wedding, corporate gathering, or special celebration. Our diverse menu options cater to various preferences, offering a fusion of flavors that cater to discerning palates. We believe in not just providing a meal but creating a culinary journey that adds a distinctive touch to your occasion.",
    SercicesPara2:"Our professional and attentive staff ensures that every detail of your catering experience is seamlessly executed, from menu planning to presentation. We prioritize flexibility, allowing customization to suit the unique requirements of your event. Whether an intimate affair or a grand celebration, trust banquet hall with catering in kandivali to elevate your event with delectable cuisine and impeccable service.Choose our  banquet hall with catering in kandivali for a culinary adventure that transcends expectations, turning your event into a gastronomic delight. Join us in savoring the art of catering, where every dish tells a story of passion, precision, and unparalleled taste.",
    headingPoint1:"Thematic Decor:",
    headingPoint2:"Catering Excellence:",
    headingPoint3:"Experienced Staff:",
    headingPoint4:"Audio-Visual Setup:",
    headingPoint5:"Ample Parking:",
    headingPoint6:"Accessibility:",
    ServicesPoint1:"Our decorators are skilled in crafting immersive thematic decorations that capture the essence of your engagement, creating a magical ambiance.",
    ServicesPoint2:"Our in-house culinary experts prepare a delectable range of dishes, from traditional favorites to modern delicacies, ensuring that your guests are treated to a memorable dining experience.",
    ServicesPoint3:"Our professional staff in Borivali is committed to ensuring that every detail of your engagement is executed flawlessly, allowing you to relax and enjoy the festivities.",
    ServicesPoint4:"We offer state-of-the-art audio-visual equipment to enhance your celebration, including sound systems and projectors for speeches and presentations.",
    ServicesPoint5:"Convenient parking facilities are available to accommodate your guests comfortably.",
    ServicesPoint6:"We prioritize air conditioners, exhaustion fans, dais/stage, lighting systems, sound & music systems, fire extinguishers, food serving, dining facilities, welcome carpets, and private cabins.",
  },
  // {
  //   Header: "NAMING CEREMONY",
  //   Slugs: "/naming-ceremony",
  //   ServicesImage:Gallery7,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"At lorem ipsum Hall, we take immense pride in providing couples with an exquisite and unforgettable venue for their marriage celebrations. With a commitment to excellence and a keen understanding of the profound significance of this momentous day, we offer a truly exceptional experience that transcends the ordinary.",
  //   SercicesPara2:"Our banquet halls are thoughtfully designed, exuding an air of sophistication and opulence. Whether you envision an intimate and romantic ceremony or a grand, extravagant affair, our versatile spaces can be tailored to your specific needs and preferences. lorem ipsum Hall serves as a canvas where your dreams and love story come to life, where every detail is meticulously curated to ensure your marriage celebration is nothing short of magical.",
  //   headingPoint1:"Bespoke Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Dedicated and Attentive Staff:",
  //   headingPoint6:"Inclusivity:",
  //   ServicesPoint1:"Our talented decorators work closely with you to create a visually stunning atmosphere that embodies the essence of your love story.",
  //   ServicesPoint2:"Our in-house culinary team comprises skilled chefs who are passionate about creating a culinary journey that resonates with your palate and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from top-tier sound systems for crystal-clear vows to dazzling visual displays that enhance your reception's ambiance.",
  //   ServicesPoint4:"Our seasoned wedding coordinators are not just professionals; they are partners in ensuring that every element of your special day unfolds seamlessly, allowing you to fully immerse yourself in the celebration.",
  //   ServicesPoint5:"Our passionate and courteous staff is dedicated to ensuring that your marriage celebration is executed flawlessly, from the initial setup to the final moments of your event.",
  //   ServicesPoint6:"We prioritize inclusivity by offering wheelchair accessibility, ensuring that all your loved ones can participate fully in the joy of your union.",
  // },
  // {
  //   Header: "BABY SHOWER",
  //   Slugs: "/baby-shower",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a classic, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "MEHANDI",
  //   Slugs: "/mehandi",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a classic, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "SANGEET",
  //   Slugs: "/sangeet",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a classic, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "CORPORATE EVENTS",
  //   Slugs: "/corporate-event",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a classic, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
  // {
  //   Header: "STAY FOR GUEST",
  //   Slugs: "/stay-for-guest",
  //   ServicesImage:Gallery8,
  //   SercicesHeading1:"RECEPTION",
  //   SercicesHeading2:"Service Features",
  //   SercicesPara1:"lorem ipsum Hall is your premier destination for hosting an elegant and unforgettable reception that will leave a lasting impression on both you and your guests. Our commitment to excellence and our dedication to creating an ambiance of grandeur set us apart as a unique and exceptional venue for your reception.",
  //   SercicesPara2:"Our reception halls are designed to exude sophistication and charm. Whether you envision a classic, timeless celebration or a modern, chic soirée, our versatile spaces can be tailored to your specific style and preferences. lorem ipsum Hall serves as a canvas where your vision for the perfect reception comes to life, where every detail is carefully considered to ensure a memorable and enchanting event.",
  //   headingPoint1:"Personalized Decor:",
  //   headingPoint2:"Culinary Excellence:",
  //   headingPoint3:"Cutting-Edge Technology:",
  //   headingPoint4:"Personalized Event Coordination:",
  //   headingPoint5:"Ample Parking:",
  //   headingPoint6:"Dedicated and Attentive Staff:",
  //   ServicesPoint1:" Our talented decorators work closely with you to create an atmosphere that is not only visually stunning but also resonates with your unique love story.",
  //   ServicesPoint2:"Our in-house culinary team is comprised of skilled chefs who are passionate about crafting a culinary experience that delights your taste buds and leaves a lasting impression on your guests.",
  //   ServicesPoint3:"We offer state-of-the-art audio-visual equipment, from premium sound systems to dazzling visual displays, ensuring that your reception is a multisensory experience.",
  //   ServicesPoint4:"Our experienced wedding coordinators are more than professionals; they are partners in ensuring that every element of your reception unfolds seamlessly, allowing you to savor every moment of your special day.",
  //   ServicesPoint5:" We recognize the importance of your guests' convenience and provide ample parking space to accommodate everyone comfortably.",
  //   ServicesPoint6:"Our dedicated staff is committed to ensuring that your reception is executed flawlessly, from the initial setup to the final moments of your event.",
  // },
];

export default ServicesData;
